import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import drivingApi from 'api/drivingApi';
import { Button, Col, Form, FormControl, Modal, Row } from 'react-bootstrap';
import { MdEdit, MdAdd } from 'react-icons/md';
import { toastWrapper } from 'utils';
import { ROLE } from 'constants/role';
import elearningApi from 'api/elearningApi';
import CopyToClipboardButton from 'components/button/CopyToClipboardButton';

function AdminDrivingCenterPage() {
  const {role: userRole, center} = JSON.parse(localStorage.getItem('user-info'));
  const [query, setQuery] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [page, setPage] = useState(1);
  const [rowData, setRowData] = useState([]);
  const [drivingDate, setDrivingDate] = useState(new Date().toISOString().split('T')[0]);
  const [description, setDescription] = useState('');
  const [groupLink, setGroupLink] = useState('');
  const [showElearningModal, setShowElearningModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [initingElearning, setInitingElearning] = useState(false);
  const [elearningInfo, setElearningInfo] = useState(null);

  const [colDefs] = useState([
    {
      field: 'createdAt',
      headerName: 'Ngày tạo',
      flex: 1,
      cellRenderer: (data) => {
        return data.value
          ? new Date(data.value).toLocaleDateString('en-GB')
          : '';
      },
    },
    {
      field: 'name',
      headerName: 'Tên',
      flex: 6,
      editable: true,
    },
    {
      field: 'description',
      headerName: 'Mô tả',
      flex: 6,
      editable: true,
    },
    {
      field: 'address',
      headerName: 'Địa chỉ',
      flex: 6,
      editable: true,
    },
    {
      field: 'priority',
      headerName: 'Độ ưu tiên',
      flex: 6,
      editable: true,
    },
    {
      field: 'formVisible',
      headerName: 'Hiển thị trên website',
      editable: true,
    },
    {
      field: 'visible',
      headerName: 'Hiển thị',
      editable: true,
    },
    {
      field: 'action',
      headerName: 'Hành động',
      cellRenderer: (data) => {
        data = data.data;
        return (
          <div className='w-100 d-flex justify-content-center'>
            <button
              className='btn'
              onClick={() => {
                setSelectedRow(data);
                setElearningInfo({
                  cohortId: data?.cohortId,
                  categoryId: data?.categoryId,
                  admin: {
                    username: data?.adminUsername,
                    password: data?.adminPassword,
                  }
                })
                setShowElearningModal(true);
              }}
            >
              <MdEdit />
            </button>
          </div>
        );
      },
    },
  ]);

  const fetchDrivingCenters = async () => {
    drivingApi
      .queryDrivingCenters({ _id: center })
      .then((res) => {
        setRowData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onInitCenterElearning = (centerId) => {
    setInitingElearning(true);
    elearningApi
      .initCenterElearning(centerId)
      .then((res) => {
        setElearningInfo(res.data);
        toastWrapper('Khởi tạo thành công', 'success');
        fetchDrivingCenters();
      })
      .catch((err) => {
        toastWrapper('Khởi tạo thất bại', 'error');
      })
      .finally(() => {
        setInitingElearning(false);
      });
  };

  useEffect(() => {
    fetchDrivingCenters();
  }, [page, query]);
  
  const handleAddDateButton = async () => {
    const body = {
      date: new Date(drivingDate).getTime(),
      isVisible: true,
      description,
      link: groupLink,
    };

    drivingApi.addDrivingDate(body).then((res) => {
      toastWrapper('Thêm ngày thành công', 'success');
      fetchDrivingCenters();
      setShowAddModal(false);
    }).catch((err) => {
      toastWrapper(err.response.data.message, 'error');
    });
  }

  const onCellValueChanged = (event) => {
    const { data } = event;
    const body = {
      description: data.description,
      formVisible: data.formVisible,
      visible: data.visible,
      priority: data.priority,
      address: data.address,
      name: data.name,
      tel: data.tel,
      zalo: data.zalo,
    };

    drivingApi.updateDrivingCenter(data?._id, body).then((res) => {
      toastWrapper('Cập nhật thành công', 'success');
    }).catch((err) => {
      toastWrapper(err.response.data.message, 'error');
    });
  }

  console.log(elearningInfo)

  return (
    <div
      style={{
        height: '100vh',
      }}
    >
      <div className='ag-theme-quartz' style={{ height: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          onCellValueChanged={onCellValueChanged}
        />
      </div>
      {(userRole === ROLE.ADMIN || userRole === ROLE.DRIVING.ADMIN) && (
        <>
          <Modal
            show={showAddModal}
            onHide={() => setShowAddModal(false)}
            size='lg'
            backdrop='static'
          >
            <Modal.Header closeButton>
              <Modal.Title>Thêm ngày thi mới</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col>
                    <FormControl
                      className='mb-3'
                      type='date'
                      id='drivingDate'
                      name='drivingDate'
                      defaultValue={drivingDate}
                      onChange={(e) => setDrivingDate(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControl
                      className='mb-3'
                      type='text'
                      placeholder='Mô tả'
                      onChange={(e) => setDescription(e.target.value)}
                      as={'textarea'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControl
                      className='mb-3'
                      type='text'
                      placeholder='Nhóm thi'
                      onChange={(e) => setGroupLink(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='primary' onClick={handleAddDateButton}>
                Thêm
              </Button>
            </Modal.Footer>
          </Modal>
          {/* <Button
            className='rounded-circle'
            style={{
              width: '50px',
              height: '50px',
              position: 'fixed',
              bottom: '50px',
              right: '50px',
              zIndex: 1000,
            }}
            onClick={() => setShowAddModal(true)}
          >
            <MdAdd />
          </Button> */}
        </>
      )}
      <Modal
        show={showElearningModal}
        onHide={() => setShowElearningModal(false)}
        size='lg'
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Thông tin điểm thi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {elearningInfo?.cohortId ? (
            <div className='text-center'>
              <p>
                Tên đăng nhập: {elearningInfo?.admin?.username}
                <CopyToClipboardButton className='btn text-primary' value={elearningInfo?.admin?.username} />
              </p>
              <p>
                Mật khẩu: {elearningInfo?.admin?.password}
                <CopyToClipboardButton className='btn text-primary' value={elearningInfo?.admin?.password} />
              </p>
              <p>Cohort ID: {elearningInfo?.cohortId}</p>
              <p>Category ID: {elearningInfo?.categoryId}</p>
              <Button
                onClick={() =>
                  window.open('https://lms.uniapp.vn/my/courses.php')
                }
              >
                Quản lý E-learning
              </Button>
            </div>
          ) : (
            <p className='text-center'>
              <div className='mb-3'>Bạn chưa có E-learning</div>
              <Button
                onClick={() => onInitCenterElearning(selectedRow?._id)}
                disabled={initingElearning}
              >
                {initingElearning ? 'Đang khởi tạo' : 'Khởi tạo ngay'}
              </Button>
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => setShowElearningModal(false)}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminDrivingCenterPage;
